import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

function SideNav() {
  return (
    <aside className='main-sidebar elevation-4 sidebar-light-primary'>
      <Link to='/' className='brand-link bg-primary'>
        <span className='logo-mini' style={{ marginLeft: '20px', marginRight: '5px' }}>
          Ai
        </span>
        <span className='brand-text font-weight-light'>Admin</span>
      </Link>
      <div className='sidebar'>
        <nav className='mt-2'>
          <ul className='nav nav-pills nav-sidebar flex-column'>
            <li key={0} className='nav-item'>
              <NavLink to={'/'} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                <i className={`nav-icon fas fa-home`}></i>
                <p>에이전트 리스트</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default SideNav;
