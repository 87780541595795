// src/pages/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserLogin, setUserLoginSuccess } from '@store/actions/commonAction';
import { sendEmail } from '@store/actions/userAction';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userId, setUserId] = useState('');
  const [userPw, setUserPw] = useState('');
  const [finePwId, setFindPwId] = useState('');

  const onUserId = (event) => {
    setUserId(event.target.value);
  };

  const findPwId = (event) => {
    setFindPwId(event.target.value);
  };

  const onUserPw = (event) => {
    setUserPw(event.target.value);
  };

  const handleFindPassword = () => {
    setIsModalOpen(true);
  };

  const sendEmailHandler = () => {
    dispatch(
      sendEmail({
        userId: finePwId,
        callback: sendEmailCallback,
      })
    );
  };

  const sendEmailCallback = () => {
    toast.info('메일이 전송 되었습니다.');
    setIsModalOpen(false);
  };

  const onLogin = () => {
    if (!userId) {
      toast.warning('아이디를 입력해주세요.');
      return;
    }
    if (!userPw) {
      toast.warning('비밀번호를 입력해주세요.');
      return;
    } else {
      dispatch(
        setUserLogin({
          userId: userId,
          userPw: userPw,
          callback: onLoginCallback,
        })
      );
    }
  };

  const onLoginCallback = (data) => {
    if (data.data != null) {
      navigate('/');
    } else {
      toast.warning('입력하신 정보를 확인바랍니다.');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onLogin(); // 엔터키가 눌리면 로그인 실행
    }
  };

  /// 비밀번호
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ minHeight: '100vh', backgroundColor: '#f4f6f9' }}
    >
      <div className='card p-4' style={{ width: '400px', maxWidth: '90%' }}>
        {/* Header */}
        <div className='text-center mb-3'>
          <h4 className='font-weight-bold'>NEX OS</h4>
          <hr className='mt-2 mb-3' style={{ borderTop: '2px solid #007bff', width: '100%' }} />
        </div>
        <div>
          <div>
            <div className='form-group'>
              <label htmlFor='username'>아이디</label>
              <input
                type='text'
                className='form-control'
                name='userId'
                value={userId}
                onChange={onUserId}
                placeholder='아이디를 입력하세요'
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='password'>비밀번호</label>
              <input
                type='password'
                className='form-control'
                name='userPw'
                value={userPw}
                onChange={onUserPw}
                placeholder='비밀번호를 입력하세요'
                onKeyDown={handleKeyDown}
                required
              />
            </div>
            <button type='button' className='btn btn-primary btn-block' onClick={onLogin}>
              로그인.
            </button>
            <a href='#' onClick={handleFindPassword}>
              비밀번호를 잊어버렸습니다.
            </a>
            <br />
            <Link to='/SignUp'>새로운 사용자를 등록하세요.</Link>
          </div>
        </div>
      </div>
      <div>
        {/* Modal */}
        {isModalOpen && (
          <div className='modal fade show' style={{ display: 'block' }} aria-modal='true' role='dialog'>
            <div className='modal-dialog modal-dialog-centered' style={{ maxWidth: '400px' }}>
              <div className='modal-content'>
                {/* Modal Header */}
                <div
                  className='modal-header justify-content-center ml-4 mr-4'
                  style={{ borderBottom: '2px solid #007bff' }}
                >
                  <h4 className='modal-title font-weight-bold text-center'>NEX OS</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={closeModal}
                    aria-label='Close'
                    style={{ position: 'absolute', right: '10px' }}
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>

                {/* Modal Body */}
                <div className='modal-body text-center'>
                  <p className='text-muted mb-4'>
                    비밀번호를 잊으셨나요? <br /> 여기서 쉽게 새로운 비밀번호를 찾을 수 있습니다.
                  </p>
                  <div className='form-group text-left'>
                    <label htmlFor='userId' className='font-weight-bold'>
                      ID <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='userId'
                      placeholder='아이디를 입력해주세요.'
                      value={finePwId}
                      onChange={findPwId}
                    />
                  </div>
                </div>

                {/* Modal Footer */}
                <div className='modal-footer' style={{ justifyContent: 'space-between', borderTop: 'none' }}>
                  <button type='button' className='btn btn-outline-primary' onClick={closeModal}>
                    취소
                  </button>
                  <button type='button' className='btn btn-primary' onClick={sendEmailHandler}>
                    새로운 비밀번호 요청
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Overlay */}
        {isModalOpen && <div className='modal-backdrop fade show' onClick={closeModal}></div>}
      </div>
    </div>
  );
}

export default Login;
