import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

function UserMessage({ message }) {
  return (
    <>
      <div className='d-flex justify-content-end'>
        <div className='p-2 rounded agent-box bg-primary text-white'>
          <div>{message.text}</div>
        </div>
        <div className='text-center ml-2'>
          <img
            className='img-circle elevation-2'
            src='../../dist/img/user4-128x128.jpg'
            alt='User profile'
            style={{ width: '25px', border: '1px', solid: 'white' }}
          />
        </div>
      </div>
    </>
  );
}

export default UserMessage;
