import React from 'react';

function Footer() {
  return (
    <footer className='main-footer'>
      <strong>@2024 NEXLINK.CO., LTD. </strong>
      ALL RIGHTS RESERVED.
    </footer>
  );
}

export default Footer;
