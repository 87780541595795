import { fileAPI, sendAPI } from '@axios/index';

const ask = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/client/ask`, params, payload);
};

const api = {
  ask,
};

export default api;
