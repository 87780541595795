import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/userAction';
import api from '@api/api';

function* getUser({ payload }) {
  const { status, data } = yield call(api.getUser, { params: payload });
  if (status === 200) {
    payload.callback(data.data);
  }
}

function* getUserCreate({ payload }) {
  const { status, data } = yield call(api.getUserCreate, { payload: payload });
  if (status === 200) {
    payload.callback(data.data);
  }
}

function* getUserUpdate({ payload }) {
  const { status, data } = yield call(api.getUserUpdate, { payload: payload });
  if (status === 200) {
    payload.callback(data.data);
  }
}

function* getUserDetail({ payload }) {
  const { status, data } = yield call(api.getUserDetail, { payload: payload });
  if (status === 200) {
    payload.callback(data.data);
  }
}

function* getUserDelete({ payload }) {
  const { status, data } = yield call(api.getUserDelete, { payload: payload });
  if (status === 200) {
    payload.callback(data.data);
  }
}

function* sendEmail({ payload }) {
  const { status, data } = yield call(api.setUserLogin, { payload: payload });
  if (status === 200) {
    payload.callback(data.data);
  }
}

function* getUserLoginList({ payload }) {
  const { status, data } = yield call(api.getUserLoginList, { params: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

function* getUserUpdateList({ payload }) {
  const { status, data } = yield call(api.getUserUpdateList, { params: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

// watcher
function* watchUser() {
  yield takeLatest(actions.GET_USER, getUser);
  yield takeLatest(actions.GET_USER_CREATE, getUserCreate);
  yield takeLatest(actions.GET_USER_UPDATE, getUserUpdate);
  yield takeLatest(actions.GET_USER_DETAIL, getUserDetail);
  yield takeLatest(actions.GET_USER_DELETE, getUserDelete);
  yield takeLatest(actions.SEND_EMAIL, sendEmail);
  yield takeLatest(actions.GET_USER_LOGIN_LIST, getUserLoginList);
  yield takeLatest(actions.GET_USER_UPDATE_LIST, getUserUpdateList);
}

export default function* userSaga() {
  yield all([fork(watchUser)]);
}
