import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/commonAction';
import api from '@api/api';

function* setUserLogin({ payload }) {
  const { status, data } = yield call(api.setUserLogin, { payload: payload });
  if (status === 200) {
    yield put({
      type: actions.SET_USER_LOGIN_SUCCESS,
      payload: data.data,
    });
    payload.callback(data);
  }
}

// watcher
function* watchCommon() {
  // yield takeLatest(actions.SET_USER_INFO, postUserInfo);
  yield takeLatest(actions.SET_USER_LOGIN, setUserLogin);
}

export default function* commonSaga() {
  yield all([fork(watchCommon)]);
}
