import React from 'react';
import { Route, Routes } from 'react-router-dom';
import pages from './pages';
import PrivateRoute from './PrivateRoute';
import Layout from 'layouts/Layout';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import NotFoundPage from '@pages/NotFoundPage';
import NotPage from '@pages/NotPage';

function PageHome() {
  const stateSelector = createSelector(
    (state) => state.commonReducer,
    (commonReducer) => ({
      isLogin: !!commonReducer?.user?.user?.userId,
      userNm: commonReducer?.user?.user?.userNm ?? '',
      userId: commonReducer?.user?.user?.userId ?? '',
      sessDtlMenuList: commonReducer.sessDtlMenuList ?? '',
    })
  );
  const { isLogin } = useSelector(stateSelector);

  return (
    <Layout isLogin={isLogin}>
      <Routes>
        {pages.map((i) => {
          const isAuthenticated = i.isPublic || isLogin;
          return (
            <Route
              key={i.pathname}
              path={i.pathname}
              element={<PrivateRoute isAuthenticated={isAuthenticated}>{i.element}</PrivateRoute>}
            />
          );
        })}
        {/* <Route path='*' element={<NotPage />} />
        <Route path='/notAuth' element={<NotFoundPage />} /> */}
      </Routes>
    </Layout>
  );
}

export default PageHome;
