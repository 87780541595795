import React from 'react';
import { useLocation } from 'react-router-dom';
import AgentDetailTy1 from './AgentDetailTy1';
import AgentDetailTy2 from './AgentDetailTy2';
function AgentDetail() {
  const location = useLocation();
  const id = location.state?.state;

  if (id % 2 !== 0) {
    return <AgentDetailTy1 />;
  }

  return <AgentDetailTy2 />;
}

export default AgentDetail;
