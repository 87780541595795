import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ fileNm }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      {/* 임시로 pdf */}

      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
        <Viewer fileUrl={fileNm} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </>
  );
};

export default PdfViewer;
