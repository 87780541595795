import React, { useState } from 'react';
import { toast } from 'react-toastify';

function PasswordInput({ label, placeholder, onPasswordChange, error, errorMsg }) {
  const [password, setPassword] = useState(''); // 실제 비밀번호
  const [displayValue, setDisplayValue] = useState(''); // 화면에 보이는 값
  const [timeoutId, setTimeoutId] = useState(null); // 타이머 관리

  // 입력값 변경 핸들러
  const handleInputChange = (e) => {
    const value = e.target.value;

    // 비밀번호 업데이트
    const updatedPassword =
      value.length < password.length
        ? password.slice(0, value.length) // 삭제
        : password + value.slice(-1); // 추가
    setPassword(updatedPassword); // 로컬 상태만 업데이트

    // 부모 상태 업데이트를 debounce 형태로 제한
    if (onPasswordChange) {
      onPasswordChange(updatedPassword);
    }

    // 화면에 보이는 값 업데이트
    setDisplayValue(value);

    // 기존 타이머 정리
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // 새로운 타이머 설정
    const newTimeoutId = setTimeout(() => {
      setDisplayValue('*'.repeat(value.length)); // 마스킹된 값 표시
    }, 1000);
    setTimeoutId(newTimeoutId);
  };

  const handlePaste = (e) => {
    e.preventDefault(); // 붙여넣기 동작 차단
    toast.info('비밀번호 입력란에 붙여넣기를 할 수 없습니다.');
  };

  return (
    <>
      <label htmlFor='passwordInput'>
        {label ? label : '비밀번호'}
        <span className='text-danger'>*</span>
      </label>
      <input
        type='search' // 항상 텍스트 타입 유지
        className={`form-control form-control-sm ${error ? 'new-is-invalid' : ''}`}
        id='passwordInput'
        value={displayValue} // 화면에 보이는 값 표시
        onChange={handleInputChange}
        onPaste={handlePaste} // 붙여넣기 방지
        placeholder={
          placeholder ? placeholder : '영문 + 숫자 + 특수문자 8자리 이상 32자리 이하로 비밀번호를 입력해주세요.'
        }
        maxLength='32'
      />
      <div className='invalid-feedback' style={{ display: error ? 'block' : 'none' }}>
        {errorMsg ? errorMsg : '비밀번호는 영문+숫자+특수문자 8자 이상입니다.'}
      </div>
    </>
  );
}

export default PasswordInput;
