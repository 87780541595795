import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faUser, faPause, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import UserMessage from '@components/UserMessage';
import AgentMessage from '@components/AgentMessage';
import { useDispatch } from 'react-redux';
import { ask } from '@actions/agentAction';

const AgentDetail = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([
    {
      id: 1,
      sender: 'agent',
      name: '상담봇',
      text: '안녕하세요. 무엇을 도와드릴까요?',
      reaction: null, // null, 'like', 'dislike'
    },
  ]);

  const faqData = [
    { id: 1, question: 'React에서 상태(state)와 props의 차이는 무엇인가요?' },
    { id: 2, question: '컴포넌트의 라이프사이클이란 무엇인가요?' },
    { id: 3, question: 'React에서 이벤트 핸들러를 어떻게 사용하나요?' },
  ];
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null); // 입력 필드 참조 생성
  const [first, setFirst] = useState(true);
  const chatContainerRef = useRef(null);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = (text, sender) => {
    const userMessage = {
      id: messages.length + 1,
      sender: sender,
      name: '사용자',
      text: text,
      reaction: null,
    };

    // 메시지 추가 및 입력창 초기화
    setMessages([...messages, userMessage]);
    setNewMessage('');

    // 로딩 시작
    setIsLoading(true);

    //api 호출
    dispatch(
      ask({
        userId: 'admin',
        data: {
          collection_name: 'qa_01',
          question: text,
          embedding_model: 'bge-m3:latest',
          llm_model: 'bnksys/llama3-ko-8b:latest',
          strict_mode: true,
          weight_profile: 'K',
          top_k: 3,
          prompt:
            '당신은 주어진 문맥에서만 답변을 생성해야 합니다.\n규칙:\n1. 문맥에 있는 답변을 그대로 사용할 것\n2. 문맥에 없는 내용은 절대 추가하지 말 것\n3. 문맥의 답변을 수정하거나 확장하지 말 것\n\n질문: {question}\n문맥: {context}\n답변:',
        },
        callback: askCallback,
      })
    );
  };

  // 사용자 직접 입력
  const handleSendMessage = () => {
    if (first) {
      setFirst(false);
    }
    if (newMessage.trim() === '') return;
    sendMessage(newMessage, 'user');
  };

  // 클릭 했을 때
  const handleClickMessage = (faqQuestion) => {
    sendMessage(faqQuestion, 'user');
  };

  const handleStopLoading = () => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setIsLoading(false);
      setCurrentTimeout(null);
    }
  };

  const askCallback = (data) => {
    const agentMessage = {
      id: messages.length + 2,
      sender: 'agent',
      name: '상담봇',
      text: data.data.data.answer,
      reaction: null,
    };

    setMessages((prevMessages) => [...prevMessages, agentMessage]);
    setIsLoading(false);
    setCurrentTimeout(null);

    if (inputRef.current) {
      inputRef.current.focus();
    }
    setFirst(false);
  };

  const toggleReaction = (messageId, reactionType) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId
          ? {
              ...msg,
              reaction: msg.reaction === reactionType ? null : reactionType,
            }
          : msg
      )
    );
  };

  const AgentLoadingSpinner = () => (
    <div className='d-flex justify-content-start mb-3'>
      <div className='mr-2'>
        <FontAwesomeIcon icon={faUser} className='rounded-circle' />
      </div>
      <div
        className='p-2 rounded bg-dark d-flex justify-content-center align-items-center'
        style={{
          width: '70px',
          height: '70px',
          borderRadius: '10px',
        }}
      >
        <div className='spinner-border text-light' role='status'>
          <span className='sr-only'>로딩 중...</span>
        </div>
      </div>
    </div>
  );

  const renderChatMessage = (message) => {
    const isAgent = message.sender === 'agent';

    return (
      <div key={message.id} className='mb-3'>
        {isAgent ? (
          <AgentMessage
            message={message}
            toggleReaction={toggleReaction}
            first={first}
            setFirst={setFirst}
            faqData={faqData}
            handleClickMessage={handleClickMessage}
            isLoading={isLoading}
          />
        ) : (
          <UserMessage message={message} toggleReaction={toggleReaction} />
        )}
      </div>
    );
  };

  return (
    <>
      <div className='content mt-3'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title'>채팅</h3>
                </div>
                <div className='card-body'>
                  <div
                    style={{
                      border: '1px solid #dee2e6',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* 채팅 메시지 영역 */}
                    <div
                      ref={chatContainerRef}
                      style={{
                        height: '75vh',
                        overflowY: 'auto',
                        padding: '15px',
                      }}
                    >
                      {messages.map(renderChatMessage)}
                      {isLoading && <AgentLoadingSpinner />}
                    </div>

                    {/* 채팅 입력 영역 */}
                    <div className='chat-enter'>
                      <input
                        type='search'
                        ref={inputRef}
                        className='form-control mr-2'
                        placeholder='메시지를 입력하세요'
                        value={newMessage}
                        // disabled={isLoading}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && !isLoading && handleSendMessage()}
                      />
                      {!isLoading ? (
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className='text-primary'
                          style={{
                            transform: 'scale(1.5)',
                            cursor: 'pointer',
                          }}
                          onClick={handleSendMessage}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPause}
                          className='text-secondary'
                          style={{
                            transform: 'scale(1.5)',
                            cursor: 'pointer',
                          }}
                          onClick={handleStopLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentDetail;
