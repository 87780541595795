import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

function AgentMessage({ message, toggleReaction, first, faqData, handleClickMessage, isLoading }) {
  const clickFaq = (faqQuestion) => {
    handleClickMessage(faqQuestion);
  };

  return (
    <>
      <div className='d-flex justify-content-start'>
        <div className='d-flex align-items-center mr-2'>
          <img
            className='img-circle elevation-2'
            src='../../dist/img/user4-128x128.jpg'
            alt='Chatbot profile'
            style={{ width: '25px', border: '1px', solid: 'white' }}
          />
          <div className='text-muted' style={{ fontSize: '0.8rem', marginLeft: '5px' }}>
            {message.name}
          </div>
        </div>
      </div>
      <div className={`${first ? '' : 'd-flex'} justify-content-start align-items-center`}>
        <div className={`p-2 rounded ${first ? '' : 'agent-box'} bg-light`} style={first ? { width: '650px' } : {}}>
          <div>{message.text}</div>
        </div>

        {first && (
          <div className='d-flex mt-3' style={{ maxWidth: '650px' }}>
            {faqData.map((value) => (
              <div
                key={value.id}
                className='p-2 rounded bg-info mr-2'
                style={{
                  width: '30%',
                  opacity: isLoading ? 0.5 : 1, // 비활성화 시 투명도를 줄임
                  pointerEvents: isLoading ? 'none' : 'auto', // 클릭 이벤트를 막음
                }}
                onClick={() => !isLoading && clickFaq(value.question)} // 비활성화되면 클릭 안됨
              >
                {value.question}
              </div>
            ))}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
          }}
        >
          {!first && (
            <FontAwesomeIcon
              icon={faThumbsUp}
              style={{
                color: message.reaction === 'like' ? 'blue' : 'gray',
                cursor: 'pointer',
                marginBottom: '5px',
              }}
              onClick={() => toggleReaction(message.id, 'like')}
            />
          )}
          {!first && (
            <FontAwesomeIcon
              icon={faThumbsDown}
              style={{
                color: message.reaction === 'dislike' ? 'red' : 'gray',
                cursor: 'pointer',
              }}
              onClick={() => toggleReaction(message.id, 'dislike')}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AgentMessage;
