import Main from '@pages/Main';
import Login from '@pages/Login';
import SignUp from '@pages/SignUp';
import AgentList from '@pages/AgentList';
import ApproveApply from '@pages/ApproveApply';
import ApproveAwait from '@pages/ApproveAwait';
import ApproveReject from '@pages/ApproveReject';
import MyPage from '@pages/MyPage';
import AgentDetail from '@pages/AgentDetail';

const pages = [
  { pathname: '/', element: <AgentList />, name: '에이전트 목록', icon: 'fas fa-file', isPublic: true },
  { pathname: '/agentDetail', element: <AgentDetail />, name: '에이전트 상세', icon: 'fas fa-file', isPublic: true },
  { pathname: '/login', element: <Login />, name: '로그인', icon: 'fas fa-sign-in-alt', isPublic: true },
  { pathname: '/SignUp', element: <SignUp />, name: '회원가입', icon: 'fas fa-sign-in-alt', isPublic: true },
  { pathname: '/apply', element: <ApproveApply />, name: '승인 신청', icon: 'fas fa-file', isPublic: false },
  { pathname: '/await', element: <ApproveAwait />, name: '승인 대기', icon: 'fas fa-file', isPublic: false },
  { pathname: '/reject', element: <ApproveReject />, name: '승인 반려', icon: 'fas fa-file', isPublic: false },
  { pathname: '/myPage', element: <MyPage />, name: '마이페이지', icon: 'fas fa-file', isPublic: true },
];

export default pages;
