import './App.css';
import RoutesPage from './router/RoutesPage';
function App() {
  return (
    <div style={{ height: '100vh' }}>
      <RoutesPage />
    </div>
  );
}

export default App;
