export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const SET_USER_LOGIN_SUCCESS = 'SET_USER_LOGIN_SUCCESS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_INIT = 'SET_USER_INIT';

export const setUserLogin = (payload) => {
  return { type: SET_USER_LOGIN, payload };
};

export const setUserLoginSuccess = (payload) => {
  return { type: SET_USER_LOGIN_SUCCESS, payload };
};

export const setUserInfo = (payload) => {
  return { type: SET_USER_INFO, payload };
};

export const setUserInit = (payload) => {
  return { type: SET_USER_INIT, payload };
};
