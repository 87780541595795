import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { userSignUp, emailCheck } from '@store/actions/signUpAction';
import { useNavigate } from 'react-router-dom';
import { isId, isEmail, isPassword, checkFileTypes } from '../common/common';
import Select from 'react-select';
import PasswordInput from '@components/PasswordInput';
import DepartmentInput from '@components/DepartmentInput';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [otpCode, setOtpCode] = useState(null);
  const [otpValues, setOtpValues] = useState(Array(4).fill(''));
  const [otpGeneratedTime, setOtpGeneratedTime] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);
  const fileInputRef = useRef(null);
  const [tabType, setTabType] = useState('');
  const [selDep, setSelDep] = useState(0);

  const [userPw, setUserPw] = useState(''); // 실제 비밀번호 저장
  const [errorPw, setErrorPw] = useState(false); // 에러 상태

  const [confirmPw, setConfirmPw] = useState('');
  const [errorConfirmPw, setErrorConfirmPw] = useState(false);

  useEffect(() => {
    setTabType('info');
  }, []);

  const closeModal = () => setIsModalOpen(false);

  const checkOtp = () => {
    const enteredOtp = otpValues.join('');
    const currentTime = new Date(); // 현재 시간 가져오기
    let isValidOtp = enteredOtp === otpCode; // OTP가 맞는지 확인

    // OTP가 생성된 시간과 현재 시간의 차이를 20분 이내인지 체크
    const isWithin20Minutes = otpGeneratedTime && currentTime - otpGeneratedTime <= 1200000;

    if (isValidOtp && isWithin20Minutes) {
      setIsOtpValid(true); // OTP가 유효함을 표시
      setIsOtpError(false);
    } else {
      setIsOtpValid(false); // OTP가 유효하지 않음을 표시
      setIsOtpError(true);
    }

    console.log('iscavlid', isOtpValid);
  };

  const sendCheckEmail = () => {
    let newErrors = {};
    if (!isEmail(formData.email)) {
      newErrors.email = '이메일값이 유효하지 않습니다.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    setOtpCode(result);

    //시간
    setOtpGeneratedTime(new Date());
    console.log('currentTime', otpGeneratedTime);

    dispatch(
      emailCheck({
        email: formData.email,
        otp: result,
        callback: emailCheckCallback,
      })
    );
  };

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
  };

  const emailCheckCallback = () => {
    toast.info('이메일이 전송 되었습니다.');
  };

  const [formData, setFormData] = useState({
    userId: '',
    userNm: '',
    email: '',
    profile: null,
    agree: false,
  });

  const [errors, setErrors] = useState({
    userId: '',
    userNm: '',
    email: '',
    file: '',
  });

  const handleClear = (field) => {
    setFormData((prev) => ({ ...prev, [field]: field === 'profile' ? null : '' }));

    if (field === 'profile') {
      setSelectedFileName('Please register the file');
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // 파일 입력 초기화
      }
    }
  };

  const [selectedFileName, setSelectedFileName] = useState('Please register the file');

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;

    if (name === 'profile' && files.length > 0) {
      const file = files[0];

      setSelectedFileName(file.name); // 선택된 파일 이름 업데이트
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : name === 'profile' ? files[0] : value, // 체크박스 처리 추가
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    let newErrors = {};

    if (!isId(formData.userId)) {
      newErrors.userId = 'ID는 8자리 소문자 알파벳과 숫자로 구성 부탁드립니다.';
    }

    if (!isPassword(userPw)) {
      console.log('userPw', userPw);
      setErrorPw(true);
    }

    if (userPw != confirmPw) {
      console.log('userPw', userPw);
      console.log('confirmPw', confirmPw);
      setErrorConfirmPw(true);
    }

    if (!isEmail(formData.email)) {
      newErrors.email = '이메일값이 유효하지 않습니다.';
    }

    if (!formData.agree) {
      toast.error('약관 동의가 안되었습니다.');
      return;
    }

    if (!isOtpValid) {
      newErrors.email = '이메일 인증은 필수 입니다.';
    }

    if (formData.profile && !validTypes.includes(formData.profile.type)) {
      newErrors.file = 'JPG, JPEG, 또는 PNG 형식의 이미지만 업로드할 수 있습니다.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      userId: formData.userId,
      userPw: userPw,
      userPwLng: userPw.length,
      userNm: formData.userNm,
      email: formData.email,
      selDep: selDep,
      callback: signUpCallBack,
    };

    // 프로필이 있는 경우에만 추가
    if (formData.profile) {
      payload.profile = formData.profile;
    }

    dispatch(userSignUp(payload));
  };

  const pwChangeHandler = (newPassword) => {
    setUserPw(newPassword); // 부모 상태 업데이트
    setErrorPw(false); // 입력 중 에러 메시지 초기화
    setErrorConfirmPw(false);
  };

  const pwConfirmChangeHandler = (newPassword) => {
    setConfirmPw(newPassword);
    setErrorConfirmPw(false);
  };

  const agreeMentHanler = () => {
    setFormData({
      ...formData,
      agree: true,
    });
    setIsModalOpen(false);
  };

  const signUpCallBack = (data) => {
    if (data.resultCode == 0) {
      toast.success('회원가입이 완료되었습니다.');
      navigate('/login');
    } else {
      toast.warning('회원 가입이 실패 했습니다.');
    }
  };

  const handleTermsClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ minHeight: '100vh', backgroundColor: '#f4f6f9' }}
    >
      <div className='card p-4' style={{ width: '620px', maxWidth: '90%' }}>
        {/* Header */}
        <div className='text-center mb-3'>
          <h4 className='font-weight-bold'>NEX OS</h4>
          <hr className='mt-2 mb-3 w-100' style={{ borderTop: '2px solid #007bff' }} />
        </div>

        {/* Content */}
        <div className='text-center mb-3'>
          <p>새로운 사용자를 등록하세요</p>
        </div>
        <div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <div className='col-md-6'>
                  <label htmlFor='username'>
                    ID <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='search'
                    className={`form-control form-control-sm ${errors.userId ? 'new-is-invalid' : ''}`}
                    name='userId'
                    value={formData.userId}
                    onChange={handleChange}
                    placeholder='사용자 아이디를 입력해주세요.'
                    required
                    maxLength='8'
                  />
                  <div className='invalid-feedback' style={{ display: errors.userId ? 'block' : 'none' }}>
                    {errors.userId}
                  </div>
                </div>

                <div className='col-md-6 '>
                  <label htmlFor='name'>
                    이름 <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='search'
                    className={`form-control form-control-sm ${errors.userNm ? 'new-is-invalid' : ''}`}
                    name='userNm'
                    value={formData.userNm}
                    onChange={handleChange}
                    placeholder='사용자 이름을 입력해주세요.'
                    maxLength='64'
                    required
                  />
                  <div className='invalid-feedback' style={{ display: errors.userNm ? 'block' : 'none' }}>
                    {errors.userNm}
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <PasswordInput onPasswordChange={pwChangeHandler} error={errorPw} />
              </div>

              <div className='form-group'>
                <PasswordInput
                  label={'비밀번호 확인'}
                  placeholder={'비밀번호를 확인합니다.'}
                  onPasswordChange={pwConfirmChangeHandler}
                  error={errorConfirmPw}
                  errorMsg='비밀번호가 일치하지 않습니다.'
                />
              </div>

              <div className='form-group '>
                <label htmlFor='email'>이메일</label>
                <span className='text-danger'>*</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type='search'
                    className={`form-control form-control-sm ${errors.email ? 'new-is-invalid' : ''}`}
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='사용자 이메일을 입력해 주세요'
                    required
                    style={{ flex: 1, marginRight: '10px' }}
                    disabled={isOtpValid}
                  />
                  <button type='button' className='btn btn-default' onClick={sendCheckEmail} disabled={isOtpValid}>
                    발송
                  </button>
                </div>
                <div className='invalid-feedback' style={{ display: errors.email ? 'block' : 'none' }}>
                  {errors.email}
                </div>
                <div className='valid-feedback' style={{ display: isOtpValid ? 'block' : 'none' }}>
                  메일 확인 완료
                </div>
              </div>
              <div className='form-group '>
                <label htmlFor='otp'>OTP 확인</label>
                <span className='text-danger'>*</span>
                <div className='d-flex align-items-center'>
                  {otpValues.map((value, index) => (
                    <input
                      type='search'
                      key={index}
                      className='form-control form-control-sm col-2 ml-1 mr-1'
                      maxLength={1}
                      value={value}
                      disabled={!otpCode || isOtpValid}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  ))}
                  <button
                    type='button'
                    className='btn btn-default ml-2 col-3'
                    onClick={checkOtp}
                    disabled={!otpCode || isOtpValid}
                  >
                    확인
                  </button>
                </div>
                <div
                  className='invalid-feedback ml-2'
                  style={{
                    display: isOtpError ? 'block' : 'none',
                  }}
                >
                  OTP가 일치하지 않습니다.
                </div>
              </div>
              <div className='form-group'>
                <DepartmentInput onChange={(selectedDep) => setSelDep(selectedDep)} />
              </div>
              <div className='form-group row'>
                <label htmlFor='inputFile' className='col-sm-3 col-form-label'>
                  사진등록
                </label>
                <div className='custom-file '>
                  <input
                    type='file'
                    name='profile'
                    className='custom-file-input w-100 h-100'
                    onChange={handleChange}
                    ref={fileInputRef}
                    style={{ opacity: 0, zIndex: 3 }} // 여전히 클릭 가능하게 유지
                  />
                  <div
                    className='invalid-feedback ml-2'
                    style={{
                      display: errors.file ? 'block' : 'none',
                    }}
                  >
                    {errors.file}
                  </div>
                  <label className='custom-file-label ml-2 mr-2' htmlFor='inputFile'>
                    {selectedFileName}
                  </label>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-12 d-flex align-items-center mt-2'>
                  <div className='icheck-primary d-inline'>
                    <input
                      type='checkbox'
                      id='checkboxPrimary2'
                      name='agree'
                      checked={formData.agree}
                      onChange={handleChange}
                    />
                    <label htmlFor='checkboxPrimary2'></label>
                  </div>
                  <label className='form-check-label' htmlFor='agree'>
                    <span className='text-primary' onClick={handleTermsClick}>
                      약관
                    </span>
                    에 동의합니다.
                  </label>
                </div>
              </div>
              <div className='form-group d-flex justify-content-between'>
                <button type='button' className='btn btn-outline-primary' onClick={() => navigate('/login')}>
                  저는 이미 회원입니다.
                </button>
                <button type='submit' className='btn btn-primary'>
                  등록
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* 모달 컴포넌트 */}
      <div>
        {/* Modal */}
        {isModalOpen && (
          <div className='modal fade show' style={{ display: 'block' }} aria-modal='true' role='dialog'>
            <div className='modal-dialog modal-dialog-centered' style={{ maxWidth: '400px' }}>
              <div className='modal-content'>
                {/* Modal Header */}
                <div
                  className='modal-header justify-content-center ml-4 mr-4'
                  style={{ borderBottom: '2px solid #007bff' }}
                >
                  <h4 className='modal-title font-weight-bold text-center'>약관 보기</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={closeModal}
                    aria-label='Close'
                    style={{ position: 'absolute', right: '10px' }}
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='card-header p-0 pt-1 border-bottom-0 p-2 d-flex align-items-center justify-content-between'>
                  <div className='col-12 col-md-auto'>
                    <ul className='nav nav-tabs' id='custom-tabs-three-tab' role='tablist'>
                      <li className='nav-item'>
                        <a
                          className={`nav-link ${tabType === 'info' ? 'active' : ''}`}
                          id='custom-tabs-three-info-tab'
                          data-toggle='pill'
                          href='#custom-tabs-three-info'
                          role='tab'
                          aria-controls='custom-tabs-three-info'
                          aria-selected='true'
                          onClick={() => setTabType('info')}
                        >
                          이용약관
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className={`nav-link ${tabType === 'log' ? 'active' : ''}`}
                          id='custom-tabs-three-log-tab'
                          data-toggle='pill'
                          href='#custom-tabs-three-log'
                          role='tab'
                          aria-controls='custom-tabs-three-log'
                          aria-selected='false'
                          onClick={() => {
                            setTabType('log');
                          }}
                        >
                          개인정보 수집 및 이용
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Modal Body */}
                <div className='modal-body text-center'>
                  <div className='tab-content' id='custom-tabs-three-tabContent'>
                    <div
                      className={`tab-pane fade show ${tabType === 'info' ? 'active' : ''}`}
                      id='custom-tabs-three-info'
                      role='tabpanel'
                      aria-labelledby='custom-tabs-three-info-tab'
                    >
                      <div className='col-sm-12'>
                        <textarea className='form-control' id='inputModel' value='약관입니다.' disabled />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade show ${tabType === 'log' ? 'active' : ''}`}
                      id='custom-tabs-three-log'
                      role='tabpanel'
                      aria-labelledby='custom-tabs-three-log-tab'
                    >
                      <div className='col-sm-12'>
                        <textarea
                          className='form-control'
                          id='inputModel'
                          value='개인정보 수집 및 이용입니다.'
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal Footer */}
                <div className='modal-footer' style={{ justifyContent: 'space-between', borderTop: 'none' }}>
                  <button type='button' className='btn btn-outline-primary' onClick={closeModal}>
                    닫기
                  </button>
                  <button type='button' className='btn btn-primary' onClick={agreeMentHanler}>
                    위 내용에 동의 합니다.
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Overlay */}
        {isModalOpen && <div className='modal-backdrop fade show' onClick={closeModal}></div>}
      </div>
    </div>
  );
};

export default SignUp;
