import React, { useEffect, useRef, useState } from 'react';
import { setUserInit, setUserLoginSuccess } from '@store/actions/commonAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(setUserInit());
    dispatch(setUserLoginSuccess());
    navigate('/login');
  };

  const [img, setImg] = useState('../../dist/img/user4-128x128.jpg'); // 삭제 예정

  return (
    <nav className='main-header navbar navbar-expand navbar-light bg-primary'>
      <ul className='navbar-nav'>
        <li className='nav-item'>
          <div className='nav-link' data-widget='pushmenu'>
            <i className='fas fa-bars text-white header-scale'></i>
          </div>
        </li>
      </ul>
      <ul className='navbar-nav ml-auto'>
        <li className='nav-item d-none d-sm-inline-block'>
          <div className='nav-link'>
            <i className='fas fa-search text-white header-scale'></i>
          </div>
        </li>
        <li className='nav-item dropdown'>
          <div className='nav-link' data-toggle='dropdown'>
            <i className='fas fa-bell text-white header-scale'></i>
            <span className='badge badge-danger navbar-badge'>5</span>
          </div>
          <div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
            <span className='dropdown-item dropdown-header'>5 Notifications</span>
            <div className='dropdown-divider'></div>
            <div className='dropdown-item'>
              <i className='fas fa-envelope mr-2'></i> 4 new messages
              <span className='float-right text-muted text-sm'>3 mins</span>
            </div>
            <div className='dropdown-divider'></div>
            <div className='dropdown-item dropdown-footer'>See All Notifications</div>
          </div>
        </li>
        <li className='nav-item dropdown'>
          <div className='nav-link' data-toggle='dropdown'>
            <img
              className='img-circle elevation-2'
              style={{ width: '25px', border: '1px solid white' }}
              src={img}
              alt='User profile'
            />
          </div>

          <div className='dropdown-menu dropdown-menu-sm dropdown-menu-right'>
            <div className='dropdown-item' onClick={() => navigate('/mypage')}>
              마이페이지
            </div>
            <div className='dropdown-divider'></div>
            <div className='dropdown-item' onClick={logout}>
              로그아웃
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
