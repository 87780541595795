import * as actions from '@actions/commonAction';

const INIT_STATE = {
  user: {
    userId: '',
    userNm: '',
    userSN: '',
  },
  loading: false,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.SET_USER_INIT:
      return INIT_STATE;
    case actions.SET_USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
