import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function ApproveAwait() {
  const navigate = useNavigate();

  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ minHeight: '100vh', backgroundColor: '#f4f6f9' }}
    >
      <div className='card p-4 text-center' style={{ width: '400px', maxWidth: '100%' }}>
        {/* Header */}
        <div className='mb-3'>
          <h5 className='font-weight-bold'>NEX OS</h5>
          <hr className='mt-2 mb-3' style={{ borderTop: '2px solid #007bff', width: '100%' }} />
        </div>

        {/* Image Placeholder */}
        <div
          className='img-placeholder bg-light mx-auto mb-3 d-flex justify-content-center align-items-center'
          style={{ width: '80px', height: '80px', fontSize: '48px', border: '1px solid #ccc' }}
        >
          <i className='fas fa-image text-secondary'></i>
        </div>

        {/* Message */}
        <p className='text-muted'>
          이용 승인을 기다리고 있습니다.
          <br />
          잠시만 기다려 주십시오.
          <br />
          문의사항은 담당자에게 연락주시기 바랍니다.
        </p>
        <p>담당자 : test@test.com</p>
      </div>
    </div>
  );
}

export default ApproveAwait;
