import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ko } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import Card from '@components/Card';

function AgentList(props) {
  // Redux 디스패치 및 셀렉터 사용
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user?.user);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
  }, [dispatch]);
  // 상태 변수 선언

  const cards = [
    {
      id: 1,
      title: '에이전트 이름1',
      description: '설명1',
      users: '사용자 조직명1, 조직명2',
      manager: '관리자 이름1',
    },
    {
      id: 2,
      title: '에이전트 pdf2',
      description: '설명2',
      users: '사용자 조직명3, 조직명4',
      manager: '관리자 이름2',
    },
    {
      id: 3,
      title: '에이전트 이름3',
      description: '설명3',
      users: '사용자 조직명5, 조직명6',
      manager: '관리자 이름3',
    },
    {
      id: 4,
      title: '에이전트 pdf4',
      description: '설명4',
      users: '사용자 조직명7, 조직명8',
      manager: '관리자 이름4',
    },
    {
      id: 5,
      title: '에이전트 이름5',
      description: '설명5',
      users: '사용자 조직명9, 조직명10',
      manager: '관리자 이름5',
    },
  ];

  const clearHandler = () => {};

  return (
    <>
      <div className='content'>
        <div className='container-fluid'>
          <div className='row mb-2'>
            <div className='content-header'>
              <div className='col-12 d-flex align-items-center w-100'>
                <h4>에이전트 리스트</h4>
              </div>
            </div>
          </div>
        </div>

        {/* 콘텐츠 영역 */}
        <div className='content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  {/* 카드 헤더 */}
                  <div className='card-header d-flex flex-column'>
                    <h3 className='card-title'>에이전트 리스트</h3>
                    <div className='row align-items-center mt-3'></div>
                  </div>

                  {/* 카드 본문 */}
                  <div className='row col-12 col-mb-12 m-2'>
                    {cards.map((card) => (
                      <div
                        className='col-md-4 col-sm-6 col-12'
                        key={card.id}
                        onClick={(event) => {
                          navigate('/agentDetail', { state: { state: card.id, type: 'type' } });
                        }}
                      >
                        <Card
                          title={card.title}
                          description={card.description}
                          users={card.users}
                          manager={card.manager}
                        />
                      </div>
                    ))}
                  </div>

                  {/* 카드 푸터 */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentList;
