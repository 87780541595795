export const USER_SIGN_UP = 'USER_SIGN_UP';
export const EMAIL_CHECK = 'EMAIL_CHECK';
export const DEP_LIST = 'DEP_LIST';

export const userSignUp = (payload) => {
  return { type: USER_SIGN_UP, payload };
};

export const emailCheck = (payload) => {
  return { type: EMAIL_CHECK, payload };
};

export const depList = (payload) => {
  return { type: DEP_LIST, payload };
};
