import React from 'react';
import Header from 'layouts/Header';
import SideNav from 'layouts/SideNav';
import Footer from 'layouts/Footer';

const Layout = ({ children, isLogin }) => {
  if (isLogin) {
    return (
      <div className='wrapper'>
        <Header />
        <SideNav />
        <div className='content-wrapper'>{children}</div>
        <Footer />
      </div>
    );
  } else {
    return <div>{children}</div>;
  }
};

export default Layout;
