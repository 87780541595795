import { fileAPI, sendAPI } from '@axios/index';

const setUserLogin = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user/get-user-login`, params, payload);
};

const getUser = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user/get-user-list`, params, payload);
};

const getUserCreate = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user/get-user-create`, params, payload);
};

const getUserUpdate = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user/get-user-update`, params, payload);
};

const getUserDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user/get-user-detail`, params, payload);
};

const getUserDelete = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user/get-user-delete`, params, payload);
};

const sendEmail = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user/get-pass-reset`, params, payload);
};

const getUserLoginList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user/get-user-login-list`, params, payload);
};

const getUserUpdateList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user/get-user-update-list`, params, payload);
};

const getResourceList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/resource/get-resource-list`, params, payload);
};

const getResourceUpdateList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/resource/get-resource-update-list`, params, payload);
};

const getResourceUserList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/resource/get-resource-user-list`, params, payload);
};

const getResourceDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/resource/get-resource-detail`, params, payload);
};

const resourceCreate = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/resource/resource-create`, params, payload);
};

const resourceUpdate = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/resource/resource-update`, params, payload);
};

const resourceUserUpdate = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/resource/resource-user-update`, params, payload);
};

const getUserRoleList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user-role/get-user-role-list`, params, payload);
};

const createUserRole = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user-role/create-user-role`, params, payload);
};

const getUserRoleDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user-role/get-user-role-detail`, params, payload);
};

const getOndDepList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user-role/get-user-role-one-depth`, params, payload);
};

const getUserRoleDetailList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user-role/get-user-role-detail-list`, params, payload);
};

const updateUserRoleDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user-role/update-user-role-detail`, params, payload);
};

const updateUserRole = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/rest/user-role/update-user-role`, params, payload);
};

const getUserRoleUpdateLogList = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/user-role/get-user-role-update-log-list`, params, payload);
};

const getVectorCheck = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/vector/get-vector-check`, params, payload);
};

const vectorCreate = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/rest/vector/vector-create`, params, payload);
};

const getVectorList = ({ params = {}, payload = {} }) => {
  return fileAPI('GET', `/rest/vector/get-vector-list`, params, payload);
};

const getVectorDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('GET', `/rest/vector/get-vector-detail`, params, payload);
};

const api = {
  setUserLogin,
  getUser,
  getUserCreate,
  getUserUpdate,
  getUserDetail,
  getUserDelete,
  getVectorCheck,
  vectorCreate,
  getVectorDetail,
  getVectorList,
  sendEmail,
  getUserLoginList,
  getUserUpdateList,
  getResourceList,
  getResourceUpdateList,
  getResourceUserList,
  getResourceDetail,
  resourceCreate,
  resourceUpdate,
  resourceUserUpdate,
  getUserRoleList,
  createUserRole,
  getUserRoleDetail,
  getOndDepList,
  getUserRoleDetailList,
  updateUserRoleDetail,
  updateUserRole,
  getUserRoleUpdateLogList,
};

export default api;
