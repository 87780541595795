import React from 'react';
import { useNavigate } from 'react-router-dom';

const Card = ({ title, description, users, manager }) => {
  const navigate = useNavigate();

  return (
    <div className='card agent-card'>
      <div className='card-body'>
        <div className='d-flex align-items-start'>
          {/* 이미지 자리 */}
          <div className='img-placeholder bg-light d-flex justify-content-center align-items-center'>
            <i className='fas fa-image text-secondary'></i>
          </div>
          {/* 텍스트 정보 */}
          <div className='ml-3'>
            <h4>{title}</h4>
            <p className='text-muted'>{description}</p>
            <p className='text-muted'>{users}</p>
            <p className='text-muted'>{manager}</p>
          </div>
        </div>
        {/* 보기 버튼 */}
        <div className='overlay'>
          <span className='text-white font-weight-bold text-uppercase'>보기 &gt;</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
