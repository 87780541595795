import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ResponsivePagination from 'react-responsive-pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MyPageInfo from '@pages/mypage/MyPageInfo';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';
import { getUserLoginList } from '@actions/userAction';
import moment from 'moment';
import { PAGE_SIZE } from 'common/constants';

function MyPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [size] = useState(PAGE_SIZE); // 한 페이지당 표시할 항목 수
  const [tabType, setTabType] = useState('');
  const user = useSelector((state) => state.commonReducer.user.user);
  const [userLoginList, setUserLoginList] = useState([]);
  const [currentLoginPage, setCurrentLoginPage] = useState(1);
  const [totalLoginPages, setTotalLoginPages] = useState(1);

  const clearHandler = () => {
    setTabType('info');
  };

  useEffect(() => {
    getUserLoginRequest();
    clearHandler();
  }, [dispatch, currentLoginPage]);

  const getUserLoginCallback = (data) => {
    setUserLoginList(data.data.userLoginList);
    setTotalLoginPages(Math.ceil((data.data.totalCount || 0) / size));
  };

  // 사용자 로그인 목록
  const getUserLoginRequest = (userSn) => {
    dispatch(
      getUserLoginList({
        userSn: user.userSn,
        size: size,
        page: currentLoginPage,
        callback: getUserLoginCallback,
      })
    );
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const datePickerRef = useRef(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus(); // DatePicker의 포커스를 강제로 설정하여 달력 오픈
    }
  };

  const LoginPageChangeHandler = (page) => {
    setCurrentLoginPage(page);
  };

  return (
    <>
      <div className='content-header'>
        <div className='container-fluid'>
          <div className='row mb-1'>
            <div className='col-12 d-flex align-items-center w-100'>
              <h4>마이 페이지</h4>
            </div>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card card-tabs'>
                <div className='card-header d-flex flex-column'>
                  <h3 className='card-title'>마이 페이지</h3>
                </div>
                <div className='card-header p-0 pt-1 border-bottom-0 p-2 d-flex align-items-center justify-content-between'>
                  <div className='col-12 col-md-auto'>
                    <ul className='nav nav-tabs' id='custom-tabs-three-tab' role='tablist'>
                      <li className='nav-item'>
                        <a
                          className={`nav-link ${tabType === 'info' ? 'active' : ''}`}
                          id='custom-tabs-three-info-tab'
                          data-toggle='pill'
                          href='#custom-tabs-three-info'
                          role='tab'
                          aria-controls='custom-tabs-three-info'
                          aria-selected='true'
                          onClick={() => setTabType('info')}
                        >
                          기본정보
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className='ml-auto'></div>
                </div>
                <div className='card-body'>
                  <div className='tab-content' id='custom-tabs-three-tabContent'>
                    <div
                      className={`tab-pane fade show ${tabType === 'info' ? 'active' : ''}`}
                      id='custom-tabs-three-info'
                      role='tabpanel'
                      aria-labelledby='custom-tabs-three-info-tab'
                    >
                      <MyPageInfo />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='content' style={{ display: tabType === 'info' ? 'block' : 'none' }}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card card-tabs'>
                <div className='card-body'>
                  <div className='tab-content' id='custom-tabs-three-tabContent'>
                    <div
                      className={`tab-pane fade show ${tabType === 'info' ? 'active' : ''}`}
                      id='custom-tabs-three-log'
                      role='tabpanel'
                      aria-labelledby='custom-tabs-three-log-tab'
                    >
                      {/* Add posts and other activity content here */}

                      <div className='col-12'>
                        <div className='card-header p-0 pb-3 d-flex justify-content-between align-items-center'>
                          <div className='row'>
                            <h3 className='card-title'>로그인 정보</h3>
                          </div>
                          <div className='d-flex ml-auto'>
                            <div className='col-12 col-md-auto mb-2 mb-md-0'>
                              <div className='input-group date ' id='reservationdate' data-target-input='nearest'>
                                <DatePicker
                                  ref={datePickerRef} // ref 설정
                                  className='form-control datetimepicker-input form-control-sm no-pointer-events'
                                  locale={ko}
                                  dateFormat='yyyy-MM-dd' // 날짜 형태
                                  shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                  minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                  maxDate={new Date()}
                                  selected={selectedDate}
                                  onChange={(date) => setSelectedDate(date)}
                                  popperContainer={({ children }) => <div style={{ zIndex: 1000 }}>{children}</div>}
                                />
                                <div className='input-group-append' onClick={handleIconClick}>
                                  <div className='input-group-text'>
                                    <i className='fa fa-calendar'></i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 col-md-auto'>
                              <div className='input-group'>
                                <div className='input-group-append'>
                                  <button className='btn btn-secondary btn-sm'>검색</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='card-body table-responsive p-0 overflow-x-auto'>
                          <table className='table table-hover text-nowrap table-fixed '>
                            <thead>
                              <tr>
                                <th className='t-w-no'>ID</th>
                                <th className='t-w-260'>사용자 ID</th>
                                <th className='t-w-260'>로그인 날짜</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userLoginList.length > 0 ? (
                                userLoginList.map((data, index) => (
                                  <tr key={index}>
                                    <td>{data.userLoginSn}</td>
                                    <td>{data.userLoginSn}</td>
                                    <td>{data.loginDttm != '' ? moment(data.loginDttm).format('YYYY-MM-DD') : ''}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan='3'>데이터가 없습니다.</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className='card-footer'>
                          <div className='row align-items-center w-100'>
                            {/* 왼쪽 정렬 - Showing entries */}
                            <div className='col-md-6 d-flex justify-content-start'>
                              <h3 className='card-title'>
                                Showing <span>{currentLoginPage}</span> to <span>{size}</span> of{' '}
                                <span>{totalLoginPages}</span> entries
                              </h3>
                            </div>

                            <div className='col-md-6 d-flex justify-content-end'>
                              <ResponsivePagination
                                current={currentLoginPage}
                                total={totalLoginPages}
                                onPageChange={LoginPageChangeHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPage;
