import axios from 'axios';

const API_URL = '/';
/**
 * request 전 토큰 check
 * @param {*} config
 * @returns
 */
const beforeReqCheckToken = async (config) => {
  return config;
};

const customAxios = axios.create({
  baseURL: API_URL,
  // headers: {
  //   Accept: 'application/json',
  //   'Content-Type': 'application/json;charset=UTF-8',
  //   Authorization: '',
  // },
});
customAxios.interceptors.request.use(beforeReqCheckToken);

export const sendAPI = async (method, url, params, payload, isLoading = true) => {
  try {
    const filteredParams = Object.keys(params)
      .filter((key) => typeof params[key] !== 'function') // 함수가 아닌 것만 필터링
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {});

    const { status, data } = await customAxios({
      method,
      url,
      params: filteredParams,
      data: payload,
    });

    return { status, data };
  } catch (error) {
    const { status, data } = error.response;
    return { status, data };
  } finally {
  }
};

const filesAxios = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data;charset=UTF-8',
    Authorization: '',
  },
});
filesAxios.interceptors.request.use(beforeReqCheckToken);

export const fileAPI = async (method, url, params, payload, isLoading = true) => {
  try {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (key === 'files') {
        payload[key].forEach((file) => {
          formData.append('files', file);
        });
      } else {
        formData.append(key, payload[key]);
      }
    });

    const { status, data } = await filesAxios({
      method,
      url,
      params,
      data: formData,
    });

    return { status, data };
  } catch (error) {
    const { status, data } = error.response;
    return { status, data };
  } finally {
  }
};
