import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/agentAction';
import api from '@api/api2';

function* ask({ payload }) {
  const { status, data } = yield call(api.ask, { payload: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

// watcher
function* watchUser() {
  yield takeLatest(actions.ASK, ask);
}

export default function* agentSaga() {
  yield all([fork(watchUser)]);
}
