import moment from 'moment';

export const getDateByformat = (dateString, formatString) => {
  try {
    return moment(dateString).format(formatString);
  } catch {
    return dateString ?? '';
  }
};

export const strToPriceFormat = (str) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }

  return str
    .toString()
    .replace(/[^0-9]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* 빈값 체크 */
export const isValue = (value) => {
  return !(value === null || value === undefined || (typeof value === 'string' && value.trim() === ''));
};

/* 배열 빈값 체크 */
export const isArray = (value) => {
  return value.length == 0 ? false : true;
};

/* 텍스트 길이 체크 */
export const getTextLength = (value, length) => {
  let textLength = value.length;
  return textLength <= length ? true : false;
};

/* 여러 파일의 형식 체크 */
export const checkFileTypes = (files, allowedExtensions) => {
  return files.every((file) => {
    const fileName = typeof file === 'string' ? file : file.name; // 파일 객체일 경우 이름 추출
    const extension = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(extension);
  });
};

/* 특수 문자 확인 */
export const checkSpecialCharacters = (value, specialChars) => {
  const esChars = specialChars.map((char) => `\\${char}`).join('');
  const pattern = new RegExp(`[${esChars}]`);
  return pattern.test(value);
};

/* 휴대폰 번호 유효성 체크 */
export const isMobile = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regPhoneNumber = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
  return regPhoneNumber.test(value);
};

/* 일반 전화 번호 유효성 체크 */
export const isTel = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regPhoneNumber = /^(070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
  return regPhoneNumber.test(value);
};

/* 아이디 체크 */
export const isId = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regex = /^(?=.*[a-z])[a-z\d]{8,20}$/i;
  return regex.test(value);
};

/* 패스워드 체크 */
export const isPassword = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^?&#])[A-Za-z\d@$!%*^?&#]{8,32}$/;
  return regex.test(value);
};

/* 이메일 유효성 체크 */
export const isEmail = (value) => {
  if (value === '') return false;

  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{0,}$/;
  //const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regex.test(value);
};

/* 숫자로만 이루어진 문자열을 검사하는 정규식 */
export const isNumber = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

/* 천 단위로 콤마를 추가 */
export const setNumberComma = (value) => {
  if (!isValue(value)) {
    return '';
  }
  // 입력 값에서 숫자와 콤마만 남기기
  const numericValue = value.replace(/[^\d]/g, '');

  // 콤마 추가
  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* 날짜 YYYYMMDD에 FORMAT 추가 */
export const setDateFormat = (value, format = 'YYYY.MM.DD') => {
  if (!isValue(value)) {
    return '';
  }

  let formats = [
    'YYYYMMDD',
    'YYYY-MM-DD',
    'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss.s',
    'YYYYMMDDHHmmss',
    'YYYYMMDDHHmm',
  ];
  let formattedDate = ''; // 변환된 날짜를 저장할 변수
  // 유효한 형식을 찾아서 변환
  for (let fmt of formats) {
    let date = moment(value, fmt, true); // strict parsing
    if (date.isValid()) {
      formattedDate = date.format(format); // 원하는 포맷으로 날짜를 변환하여 변수에 저장
      break; // 유효한 날짜를 찾으면 반복을 멈춥니다.
    }
  }

  if (!formattedDate) {
    console.warn('No valid date format found for:', value);
  }

  return formattedDate;
};

/* 화면상에 권한 관리 */
export const setDisplay = (auth) => {
  const authWYn = auth?.authWrYn === 'Y' ? true : false;
  const authXYn = auth?.authDeYn === 'Y' ? true : false;

  if (!authWYn) {
    const dispSv = document.querySelectorAll('[class*="authWrYn"]');

    dispSv.forEach((element) => {
      element.style.display = 'none';
    });
  }

  if (!authXYn) {
    const dispRm = document.querySelectorAll('[class*="authDeYn"]');

    dispRm.forEach((element) => {
      element.style.display = 'none';
    });
  }
};
