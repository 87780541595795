import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/signUpAction';
import api from '@api/api2';

function* userSignUp({ payload }) {
  const { status, data } = yield call(api.signUp, { payload: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

function* emailCheck({ payload }) {
  const { status, data } = yield call(api.emailCheck, { payload: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

function* depList({ payload }) {
  const { status, data } = yield call(api.depList, { payload: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

// watcher
function* watchUser() {
  yield takeLatest(actions.USER_SIGN_UP, userSignUp);
  yield takeLatest(actions.EMAIL_CHECK, emailCheck);
  yield takeLatest(actions.DEP_LIST, depList);
}

export default function* pointSaga() {
  yield all([fork(watchUser)]);
}
