import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

function MyPageInfo(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userNm, setUserNm] = useState('');
  const [userId, setUserId] = useState('');
  const [userPw, setPassword] = useState('');
  const [displayPw, setDisplayPassword] = useState('');

  const [regDate, setRegDate] = useState('');
  const [updateDate, setUpdateDate] = useState('');

  const [isDisabled, setIsDisabled] = useState(true);

  const [userEmail, setUserEmail] = useState('');
  const [memo, setMemo] = useState('');
  const [state, setState] = useState('');
  const fileInputRef = useRef(null);
  const [userProfileNm, setUserProfileNm] = useState('Please register the file');
  const [userProfile, setUserProfile] = useState(null);

  const [errors, setErrors] = useState({
    userId: '',
    userPw: '',
    userNm: '',
    userEmail: '',
  });

  const getPypiDetailRequest = () => {
    setState('업로드 중');
    setUserNm('사용자 명');
    setUserId('admin12');
    setMemo('설명');
    setUserEmail('등록된 이메일 주소 노출');
  };

  // 수정 버튼
  const updateHandler = () => {
    //TODO 업데이트
    //TODO 값 초기화
  };

  const clearHandler = () => {
    setUserProfile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // 파일 입력 초기화
    }
  };

  const pwChangeHandler = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    getPypiDetailRequest();
    clearHandler();

    // dispatch 관련 로직 추가
  }, [dispatch]);

  const handlePwChange = (e) => {
    let newValue = e.target.value;
    const inputType = e.nativeEvent.inputType;
    const prevValue = userPw;

    // input이 password 타입일 때의 특수한 처리
    if (newValue.includes('*')) {
      // Backspace/Delete 처리
      if (inputType === 'deleteContentBackward' || inputType === 'deleteContentForward') {
        newValue = prevValue.slice(0, -1);
      } else {
        // 새로운 문자 입력 처리
        const newChar = newValue.length > prevValue.length ? newValue[newValue.length - 1] : '';
        newValue = prevValue + newChar;
      }
    }

    // 실제 비밀번호 업데이트
    setPassword(newValue);
    setDisplayPassword('*'.repeat(newValue.length));
  };

  const handleProfileChange = (e) => {
    const files = Array.from(e.target.files);
    setUserProfile(files[0]);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className='row'>
        {/*1. 첫번째 */}
        <div className='col-12 col-sm-6'>
          <div className='row'>
            <div className='col-4'>
              <label className='font-weight-bold'>ID</label>
            </div>
            <div className='col-4'>
              <label className='font-weight-bold'>이름</label>
            </div>
            <div className='col-4'>
              <label className='font-weight-bold'>비밀번호</label>
            </div>
          </div>
          <div className='row'>
            <div className='col-4 mb-2'>{userId}</div>
            <div className='col-4 mb-2'>{userNm}</div>
            <div className='col-4 mb-2'>
              <input
                type='search'
                className={`form-control form-control-sm ${errors.userPw ? 'new-is-invalid' : ''}`}
                value={displayPw}
                onChange={handlePwChange}
                placeholder='비밀번호를 입력해주세요.'
                disabled
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <label className='font-weight-bold'>
                이메일<span className='text-danger'>*</span>
              </label>
            </div>
            <div className='col-4'></div>
            <div className='col-4'>
              <button
                className='btn btn-secondary btn-sm'
                style={{ width: '110px' }}
                onClick={(event) => {
                  pwChangeHandler();
                }}
              >
                비밀번호 변경
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-4 mb-1'>{userEmail}</div>
            <div className='col-4 mb-1'></div>
            <div className='col-4 mb-1'></div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <label className='font-weight-bold'>사진</label>
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <div className='text-center'>
                <img className='img-circle' style={{ width: '50px' }} src={userProfile} alt='User profile' />
              </div>
            </div>
            <div className='col-10'>
              <div className='custom-file '>
                <input type='file' className='custom-file-input' disabled={isDisabled} />
                <label className='custom-file-label ml-2 mr-2' htmlFor='inputFile'>
                  {userProfileNm}
                </label>
              </div>
            </div>
          </div>
          <div className='col-1 mb-1'></div>
        </div>
        {/*2. 두번째 */}
        <div className='col-12 col-sm-6'>
          <div className='row col-12'>
            <label className='font-weight-bold'>권한</label>
          </div>
          <div className='row col-12 mb-2'>
            <select className='form-control form-control-sm' disabled={isDisabled}>
              <option>선택한 권한 명 노출</option>
            </select>
          </div>
          <div className='row col-12'>
            <label className='font-weight-bold'>소속 팀</label>
          </div>
          <div className='row'>
            <div className='col-4 mb-1'>
              <select className='form-control form-control-sm' disabled={isDisabled}>
                <option>선택한 팀 노출</option>
              </select>
            </div>
            <div className='col-4 mb-1'>
              <select className='form-control form-control-sm' disabled={isDisabled}>
                <option>선택한 팀 노출</option>
              </select>
            </div>
            <div className='col-4 mb-1'>
              <select className='form-control form-control-sm' disabled={isDisabled}>
                <option>선택한 팀 노출</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-footer mt-2 d-flex justify-content-end text-right' style={{ width: '100%' }}>
          <button
            className='btn btn-secondary btn-sm'
            style={{ width: '110px', display: isDisabled ? 'block' : 'none' }}
            onClick={(event) => {
              setIsDisabled(false);
            }}
          >
            수정
          </button>
          <div className='btn-group' style={{ display: isDisabled ? 'none' : 'block' }}>
            <button
              className='btn btn-secondary btn-sm mr-2'
              style={{ width: '110px' }}
              onClick={(event) => {
                getPypiDetailRequest();
                setIsDisabled(true);
              }}
            >
              취소
            </button>
            <button className='btn btn-primary btn-sm' style={{ width: '110px' }} onClick={updateHandler}>
              수정
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className='modal fade show' style={{ display: 'block' }} aria-modal='true' role='dialog'>
          <div className='modal-dialog modal-dialog-centered' style={{ maxWidth: '400px' }}>
            <div className='modal-content'>
              {/* Modal Header */}
              <div
                className='modal-header justify-content-center ml-4 mr-4'
                style={{ borderBottom: '2px solid #007bff' }}
              >
                <h4 className='modal-title font-weight-bold text-center'>NEX OS</h4>
                <button
                  type='button'
                  className='close'
                  onClick={closeModal}
                  aria-label='Close'
                  style={{ position: 'absolute', right: '10px' }}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>

              {/* Modal Body */}
              <div className='modal-body text-center'>
                <p className='text-muted mb-4'>새로운 비밀번호로 변경 합니다.</p>
                <div className='form-group text-left'>
                  <label htmlFor='userPw' className='font-weight-bold'>
                    현재 비밀번호 <span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' id='userPw' placeholder='현재 비밀번호를 입력해주세요.' />
                </div>
                <div className='form-group text-left'>
                  <label htmlFor='newPw' className='font-weight-bold'>
                    신규 비밀번호 <span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' id='newPw' placeholder='신규 비밀번호를 입력해주세요.' />
                </div>
                <div className='form-group text-left'>
                  <label htmlFor='confirmNewPw' className='font-weight-bold'>
                    신규 비밀번호 확인<span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' id='confirmNewPw' placeholder='비밀번호를 확인합니다.' />
                </div>
              </div>

              {/* Modal Footer */}
              <div className='modal-footer' style={{ justifyContent: 'space-between', borderTop: 'none' }}>
                <button type='button' className='btn btn-outline-primary' onClick={closeModal}>
                  취소
                </button>
                <button type='button' className='btn btn-primary'>
                  비밀번호 변경
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Overlay */}
      {isModalOpen && <div className='modal-backdrop fade show' onClick={closeModal}></div>}
    </>
  );
}

export default MyPageInfo;
