import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function ApproveReject() {
  const navigate = useNavigate();

  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ minHeight: '100vh', backgroundColor: '#f4f6f9' }}
    >
      <div className='card p-4 text-center' style={{ width: '400px', maxWidth: '100%' }}>
        {/* Header */}
        <div className='mb-3'>
          <h5 className='font-weight-bold'>NEX OS</h5>
          <hr className='mt-2 mb-3' style={{ borderTop: '2px solid #007bff', width: '100%' }} />
        </div>

        {/* Image Placeholder */}
        <div
          className='img-placeholder bg-light mx-auto mb-3 d-flex justify-content-center align-items-center'
          style={{ width: '80px', height: '80px', fontSize: '48px', border: '1px solid #ccc' }}
        >
          <i className='fas fa-image text-secondary'></i>
        </div>

        {/* Message */}
        <p className='text-muted'>
          귀하의 신청이 거부되었습니다.
          <br />
          담당자에게 문의 후 다시 신청해주세요.
        </p>
        <p>반려 사유 : 직원 확인이 안되었습니다.</p>
        {/* Button */}
        <button type='button' className='btn btn-primary mt-3'>
          신청
        </button>
      </div>
    </div>
  );
}

export default ApproveReject;
