import * as actions from '@actions/userAction';

const INIT_STATE = {
  user: {
    userId: '',
    userNm: '',
    userTel: '',
  },
  notifyCount: 0,
  boolPopup: false,
  loading: false,
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };    
    default:
      return state;
  }
};

export default userReducer;
