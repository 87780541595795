import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { depList } from '@store/actions/signUpAction';

function DepartmentInput({ onChange }) {
  const dispatch = useDispatch();
  const [majorList, setMajorList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [minorList, setMinorList] = useState([]);

  const [majorCategory, setMajorCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [minorCategory, setMinorCategory] = useState('');

  const resetMajorCategories = () => {
    setMajorList([]);
    setMajorCategory('');
    setSubList([]);
    setSubCategory('');
    setMinorList([]);
    setMinorCategory('');
  };

  const resetSubCategories = () => {
    setSubList([]);
    setSubCategory('');
    setMinorList([]);
    setMinorCategory('');
  };

  const resetMinorCategories = () => {
    setMinorList([]);
    setMinorCategory('');
  };

  useEffect(() => {
    getDepList();
  }, []);

  // 대분류 선택 시 중분류 옵션 업데이트
  useEffect(() => {
    getSecDepList();
  }, [majorCategory]);

  // 중분류 선택 시 소분류 옵션 업데이트
  useEffect(() => {
    getThiDepList();
  }, [subCategory]);
  const getDepListCallback = (data) => {
    setMajorList(data.data.depList);
  };

  // 대분류 리스트
  const getDepList = () => {
    resetMajorCategories();
    dispatch(
      depList({
        depType: 'H',
        parentDepSn: 0,
        callback: getDepListCallback,
      })
    );
  };

  const getSecDepListCallback = (data) => {
    setSubList(data.data.depList);
  };
  // 중분류 리스트
  const getSecDepList = () => {
    if (majorCategory) {
      resetSubCategories();
      dispatch(
        depList({
          parentDepSn: majorCategory,
          callback: getSecDepListCallback,
        })
      );
    }
  };

  const getThiDepListCallback = (data) => {
    setMinorList(data.data.depList);
  };
  // 소분류 리스트
  const getThiDepList = () => {
    if (subCategory) {
      resetMinorCategories();
      dispatch(
        depList({
          parentDepSn: subCategory,
          callback: getThiDepListCallback,
        })
      );
    }
  };

  // 대분류 선택
  const firstSelectChangeHandler = (e) => {
    const selectedValue = e.target.value;
    setMajorCategory(selectedValue);

    if (selectedValue === '') {
      resetSubCategories();
    } else {
      // 팀 2 업데이트
      getSecDepList();
    }
    if (onChange) {
      onChange(selectedValue);
    }
  };

  // 중분류 선택
  const secondSelectChangeHandler = (e) => {
    const selectedValue = e.target.value;
    setSubCategory(selectedValue);

    if (selectedValue === '') {
      resetMinorCategories();
    } else {
      // 팀 3 업데이트
      getThiDepList();
    }
    if (onChange) {
      onChange(selectedValue == 0 ? majorCategory : selectedValue);
    }
  };

  // 소분류 선택
  const minorSelectChangeHandler = (e) => {
    const selectedValue = e.target.value;
    setMinorCategory(selectedValue);

    if (onChange) {
      onChange(selectedValue == 0 ? subCategory : selectedValue);
    }
  };

  return (
    <>
      <label htmlFor='team'>소속 팀</label>
      <div className='d-flex align-items-center'>
        <select
          className='form-control form-control-sm mr-3'
          name='team1'
          value={majorCategory}
          onChange={(e) => firstSelectChangeHandler(e)}
        >
          <option value=''>선택하세요</option>
          {majorList.map((dep) => (
            <option key={dep.depSn} value={dep.depSn}>
              {dep.depNm}
            </option>
          ))}
        </select>
        <select
          className='form-control form-control-sm mr-3'
          name='team2'
          value={subCategory}
          onChange={(e) => secondSelectChangeHandler(e)}
          disabled={!majorCategory}
        >
          <option value=''>{majorCategory ? '선택하세요' : '대분류를 선택하세요.'}</option>
          {subList.length > 0 ? (
            subList.map((dep) => (
              <option key={dep.depSn} value={dep.depSn}>
                {dep.depNm}
              </option>
            ))
          ) : (
            <option disabled>선택 가능한 항목이 없습니다</option>
          )}
        </select>
        <select
          className='form-control form-control-sm'
          name='team3'
          value={minorCategory}
          onChange={(e) => minorSelectChangeHandler(e)}
          disabled={!subCategory}
        >
          <option value=''>{subCategory ? '선택하세요' : '중분류를 선택하세요.'}</option>
          {minorList.length > 0 ? (
            minorList.map((dep) => (
              <option key={dep.depSn} value={dep.depSn}>
                {dep.depNm}
              </option>
            ))
          ) : (
            <option disabled>선택 가능한 항목이 없습니다</option>
          )}
        </select>
      </div>
    </>
  );
}

export default DepartmentInput;
