export const GET_USER = 'GET_USER';
export const GET_USER_CREATE = 'GET_USER_CREATE';
export const GET_USER_UPDATE = 'GET_USER_UPDATE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DELETE = 'GET_USER_DELETE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const GET_USER_LOGIN_LIST = 'GET_USER_LOGIN_LIST';
export const GET_USER_UPDATE_LIST = 'GET_USER_UPDATE_LIST';

export const getUser = (payload) => {
  return { type: GET_USER, payload };
};

export const getUserCreate = (payload) => {
  return { type: GET_USER_CREATE, payload };
};

export const getUserUpdate = (payload) => {
  return { type: GET_USER_UPDATE, payload };
};

export const getUserSuccess = (payload) => {
  return { type: GET_USER_SUCCESS, payload };
};

export const getUserDetail = (payload) => {
  return { type: GET_USER_DETAIL, payload };
};

export const getUserDelete = (payload) => {
  return { type: GET_USER_DELETE, payload };
};

export const sendEmail = (payload) => {
  return { type: SEND_EMAIL, payload };
};

export const getUserLoginList = (payload) => {
  return { type: GET_USER_LOGIN_LIST, payload };
};

export const getUserUpdateList = (payload) => {
  return { type: GET_USER_UPDATE_LIST, payload };
};
